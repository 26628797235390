.login_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;

	.login_card {
		width: 400px;
		height: 400px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.form {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;

			.form_input {
				margin-bottom: 10px;
				width: 70%;
				padding: 10px;
				font-size: 18px;
			}
			button {
				border: none;
				cursor: pointer;
			}
		}
	}
}
