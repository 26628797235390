.container {
	display: flex;
	flex-direction: column;
	width: 80%;
	height: 100%;
	margin: 0 auto;

	.card_container {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		/* grid-template-rows: 1fr 1fr; */
		grid: 'card1 card2' 'card3 card4';
		grid-gap: 10px;

		.container_card {
			background-color: #f2f2f2;
			border-radius: 10px;
			padding: 10px;
			margin: 10px;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
				0 6px 20px 0 rgba(0, 0, 0, 0.19);
			text-align: center;
			min-height: 200px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h3 {
				font-size: 35px;
				font-weight: 500;
			}
			p {
				font-size: 50px;
				font-weight: bold;
			}
		}
	}

	.openTenders {
		grid-area: card1;
	}
	.meetings {
		grid-area: card2;
	}
	.submitted {
		grid-area: card3;
	}
	.awarded {
		grid-area: card4;
	}
}
