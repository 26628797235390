/* .nav_container {
	background-color: aquamarine;
	width: 15vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	position: absolute;
} */
.nav_item {
	padding: 10px 20px;
}
